import { createSelector } from "reselect";
import { getEventDetails } from "./events/common";
import { Map, List } from "immutable";
const getDiscountOffers = state => state.get("discountOffers");
export const getEventDiscountOfferBySlug = (state, eventSlug) => state.getIn(["discountOffers", "eventDiscountOffer", eventSlug]);
export const getDiscountLoading = createSelector(getDiscountOffers, discountOffers => {
    discountOffers.get("isDiscountOfferLoading");
});
export const getDiscountError = createSelector(getDiscountOffers, discountOffers => {
    discountOffers.get("discountOfferError");
});
export const getOfferConditionByEventSlug = createSelector(getEventDetails, eventData => {
    let offerCondition = Map({
        redeem_against_event_ids: List([]),
        redeem_against_category_ids: List([]),
        redeem_against_tag_ids: List([]),
        redeem_against_group_ids: List([]),
        redeem_against_item_ids: List([]),
    });
    if (eventData) {
        let _tags = (eventData.get("tags") || List([])).map((allTags) => typeof allTags.get("tag_id") === "string" ? allTags.get("tag_id") : allTags.getIn(["tag_id", "_id"])).filter((tagId) => tagId !== undefined);
        let item_ids = eventData.getIn(["venue", "shows"]).map((show) => show.get("items_for_sale").map(((item_for_sale) => item_for_sale.get("items").map((item) => item.get('_id'))))).flatten();
        offerCondition = offerCondition
            .set("redeem_against_event_ids", List([eventData.get("_id")]))
            .set("redeem_against_category_ids", List([eventData.getIn(["category_id", "id"])]))
            .set("redeem_against_tag_ids", _tags)
            .set("redeem_against_group_ids", List([eventData.getIn(["group_id", "_id"])]))
            .set("redeem_against_item_ids", item_ids);
    }
    return offerCondition;
});
export const getEventNameBySlug = createSelector(getEventDetails, eventData => {
    return eventData.get("name");
});
